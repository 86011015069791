.calendarToolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;

  & > button {
    margin-bottom: 0;
  }
}

.circle {
  display: inline-block;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.approved {
  background: #6610f2;
}

.unapproved {
  background: #ff4f5b;
}

.closed {
  background: #6c757d;
}

.footerWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.footerTableWrapper {
  table {
    th {
      height: 48px;
      border-top: none;
      border-bottom: none;
    }

    td {
      height: 40px;
    }
  }
}
