.dateError {
  position: absolute;
  bottom: -3px;
  left: 0;
  font-size: 10px;
  font-weight: 500;
  color: #ff4f5b;
}

.dropdownError {
  position: absolute;
  top: 41px;
  left: 0;
  font-size: 10px;
  font-weight: 500;
  color: #ff4f5b;
}

.inputError {
  position: absolute;
  bottom: -17px;
  left: 0;
  font-size: 10px;
  font-weight: 500;
  color: #ff4f5b;
}

.error {
  font-size: 10px;
  font-weight: 500;
  margin-top: 5px;
  color: #ff4f5b;
}

input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #bcbff0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.value {
  margin: 7px 0 0 10px;
  font-size: 14px;
}

.wrapDropdownList {
  position: relative;

  & > div {
    width: 100%;
    height: 100%;
  }

  i {
    position: absolute;
    top: 55%;
    right: 20px;
    background-color: #fff;
    width: 20px;
  }
}

.closeIcon {
  position: absolute;
  top: 9px;
  right: 9px;
}
