.breadcrumbs {
  display: flex;
  margin-top: 20px;
  margin-bottom: -14px;
}

.crumb {
  margin-right: 14px;
  position: relative;
  color: #7f8495;
  white-space: nowrap;

  a {
    color: #7f8495;
    font-weight: 400;

    &:hover {
      color: #4f75ff;
      text-decoration: none;
    }
  }

  &:last-child {
    a {
      color: #4f75ff;

      &:hover {
        cursor: default;
      }
    }

    &:after {
      display: none;
    }
  }

  svg {
    margin-left: 14px;

    &:hover {
      cursor: default;
    }
  }

  .home {
    margin-left: 0;

    &:hover {
      cursor: pointer;

      path {
        fill: #4f75ff;
      }
    }
  }

  .hiddenMessage {
    display: none;
  }

  a:hover + .hiddenMessage {
    position: absolute;
    bottom: 20px;
    left: -10px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      background: #7f8495;
      border-radius: 4px;
      padding: 4px;
      color: #fefefe;
      margin: 0;
    }
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #7f8495;
  }

  .dots {
    color: #7f8495;

    &:hover {
      color: #4f75ff;
    }
  }

  .button {
    border: none;
    background: transparent;
    padding: 0;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .popup {
    position: absolute;
    z-index: 1;
    text-align: left;
    top: 21px;
    border: 1px solid #e6e7ed;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }

  .popupItem {
    padding: 8px;

    &:hover {
      background: #e6e7ed;
    }

    a {
      color: #40475d;
    }
  }
}
