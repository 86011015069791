.wrapperStatus {
  display: flex;
  align-items: center;
  gap: 10px;

  .statusMark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}

.buttonsWrap {
  display: flex;
  align-items: center;
  width: 100%;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    line-height: 16px;
    border: none;
    display: flex;
    gap: 9px;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-out;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .edit {
    border: 1px solid #4f75ff;
    color: #4f75ff;

    &:hover {
      background-color: rgba(71, 115, 255, 0.2);
    }

    &:disabled {
      border: none;
      background: #dce3ff;
      color: #bec1cc;

      svg {
        path {
          stroke: #bec1cc;
          fill: #bec1cc;
        }
      }
    }
  }

  .delete {
    border: 1px solid #ff4f5b;
    color: #ff4f5b;

    &:hover {
      background-color: #ffdcde;
    }

    &:disabled {
      border: none;
      background: #ffdcde;
      color: #bec1cc;

      svg {
        path {
          stroke: #bec1cc;
          fill: #bec1cc;
        }
      }
    }
  }

  .success {
    color: #46c869;
    border: 1px solid #46c869;

    &:hover {
      background-color: #daf4e1;
    }
  }

  .complete {
    color: #40475d;
    border: 1px solid #40475d;

    &:hover {
      background-color: #bec1cc;
    }
  }

  .disabled {
    display: none;
  }
}
