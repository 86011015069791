.dropdownHeight {
  max-height: 26px;
}

.removeMargin {
  margin-bottom: -5px;
}

.marginBottom {
  margin-bottom: 14px;
}

.repeatWrap {
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 16px;
  }

  label {
    margin: 0;
  }

  input {
    height: 16px;
    width: 16px;
    box-shadow: none;
    margin-right: 8px;
  }
}

.warningText {
  max-width: 360px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 32px;
}

.toggleLabel {
  color: #7f8495;
}

.toggleLabelMargin {
  margin-left: 4px;
}
