.pagination {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .action {
    display: flex;
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;
    & button {
      border-radius: 4px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      color: #4f75ff;
      background-color: rgba(79, 117, 255, 0.05);
      margin: 0 4px;
    }
    .disabledBtn {
      opacity: 0.3;
    }
  }

  .arrowButton {
    display: flex;
    justify-content: center;
    svg {
      width: 4px;
      height: 8px;

      path {
        stroke: #4f75ff;
      }
    }
  }

  .paginationWrapper {
    display: flex;
    margin: 0;
    padding: 0;
    margin-right: 8px;

    & li {
      cursor: pointer;
      list-style: none;
    }

    .paginationItem {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      width: 26px;
      height: 26px;
      margin-left: 6px;
      text-align: center;
      color: #9fa2ae;
    }

    .activeItem {
      color: #ffffff;
      background-color: #4f75ff;
      border-radius: 4px;
    }
  }

  .selectPerPage {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;

    .showing {
      margin: 0 20px;
      color: #9fa2ae;
    }

    .selectSize {
      width: 75px;
      height: 26px;
    }
  }
}
