.rw-list {
  overflow-x: hidden;
}

.rw-list-option {
  text-overflow: ellipsis;
  overflow-x: clip;
}

.rw-widget {
  & > .rw-widget-container {
    height: 100%;
  }
}

.wrap-info-profile,
.obligation-wrap {
  .rw-widget-input {
    min-height: 40px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.wrap-info-profile-edit {
  .rw-widget-input {
    border: 1px solid transparent;
    .rw-select {
      display: none;
    }
  }
}

.btn-wrap {
  button {
    padding: 7px 12px;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.obligation-wrap {
  .rw-widget {
    width: 90%;
  }
  .rw-widget-input {
    background-color: transparent;
    .rw-select {
      display: block;
    }
  }
}

.obligation-wrap-edit-mode {
  .rw-input {
    padding-left: 0;
  }
  .rw-widget-input {
    cursor: default;
    background-color: transparent;
    border-color: #fff;
    .rw-select {
      display: none;
    }
    .rw-state-disabled {
      background: transparent;
      border: transparent;
      color: #000;
    }
    .rw-btn {
      display: none;
    }
  }
  .rw-multiselect-tag {
    margin-left: 0;
    padding-left: 0;
    display: block;
  }
}

.obligation-static-input {
  input {
    padding: 0 12px;
    max-width: 220px;
    color: #40475d;
    font-size: 14px;
    min-height: 40px;
    border: 1px solid #e6e7ed;
    border-radius: 4px;
  }
  input[disabled] {
    padding: 0;
    border: #fff;
    background: #fff;
    border-radius: 0;
  }
}

// input label
.label-wrapper {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #9fa2ae;
  margin-bottom: 4px;
}
