.headCell {
  th,
  td {
    width: 117px;
    white-space: nowrap;
  }
}

.headCellWide {
  width: 120px;
  white-space: normal;
  word-break: break-all;
}
