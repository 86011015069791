.custom-table {
  border-spacing: 0;

  td,
  th {
    height: 40px;
    min-width: 40px;
    position: relative;
  }
}

.table {
  th {
    background: #f0f4f6;
    white-space: nowrap;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.table {
  td {
    height: 52px;
    padding: 6px 8px;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.bp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-group {
    margin-bottom: 0;

    .input-group > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control {
      border-radius: 0.25rem;
    }

    .input-group > .react-datepicker__tab-loop > .react-datepicker-popper > div > .react-datepicker {
      cursor: pointer;
    }
  }
}

.filters-block {
  display: flex;
  width: 100%;

  .dropdown-filter {
    position: relative;
    padding: 0;
    margin-right: 8px;
    max-width: 220px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    .rw-widget-picker {
      height: 40px;
      border-radius: 0.25rem;
      cursor: pointer;
      margin-bottom: 1rem;
    }

    .rw-state-focus {
      background-color: #e6e7ed;
      border: none;
      color: #40475d;
    }

    .rw-state-selected {
      color: #fff;
      background-color: #4f75ff;
      border-color: #4f75ff;
    }

    .rw-dropdown-list {
      height: 40px;
      margin-bottom: 1rem;
    }

    .react-datepicker-wrapper {
      margin-bottom: 1rem;
    }

    .form-control {
      height: 40px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

.disable-edit-btn {
  cursor: not-allowed;
  background-color: rgba(69, 69, 69, 0.2);
}

.table-bordered tbody > tr td {
  border-top: 0;
}

.employee-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #4f75ff;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}

.assigneesDropdown {
  .rw-filter-input {
    width: 210px;
  }
}

.dropdownWithSearch {
  .rw-input-reset {
    border: none;
  }
}
