.name-curtail {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.cut-text {
  max-width: 300px;
}
.rbc-date-cell > a {
  pointer-events: none;
}

.no-click a {
  pointer-events: none;
}
