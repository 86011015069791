.teamsWrapper {
  display: flex;
  gap: 24px;
}

.departmentsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  flex-grow: 1;
}

.departmentCard {
  max-width: 360px;
  padding: 16px;
  border: 1px solid #e6e7ed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.total {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 16px;
}

.ceo {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.team {
  display: flex;
  align-items: center;
  gap: 4px;
}

.parentTeam {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.department {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.teams {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  line-height: 20px;
}

.circle {
  display: inline-block;
  width: 3px;
  height: 3px;
  background: #40475d;
  border-radius: 50%;
}

.arrow {
  svg {
    width: 6p;
    height: 11px;

    path {
      stroke: #4f75ff;
      stroke-width: 1px;
    }
  }
}
