.form {
  display: flex;
  flex-wrap: wrap;

  &_section {
    width: 100%;
    margin-bottom: 15px;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  .item {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    p {
      min-width: 152px;
      margin: 0;
    }
  }
}

.headerName {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 17px;
}

.subheader {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

// for BDM Funnel page
.groupNameTable {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 0;
}

.tableHeadingWrapper {
  display: flex;
  align-items: center;

  button {
    border: none;
    background: transparent;
    padding: 0 10.5px;
    margin-top: 2px;
    font-size: 25px;
    color: #7f8495;

    &:hover {
      color: #40475d;
    }
  }
}

.columnWidth {
  width: 16.5%;
}

.estimated {
  background: #dcf2f7;
}

.closed {
  background: #e6dbff;
}

.approved {
  background: #daf4e1;
}

.result {
  background: #f0f4f6;
  padding-left: 8px;
  margin-top: -16px;
}
