.container {
  color: #40475d;

  thead th {
    border-top: none;
    border-bottom: 1px solid #e6e7ed;
  }
}

.headRow {
  position: relative;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }
}

.legendBlock {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 6px;

  .roundMark {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    flex-shrink: 0;
  }
}

.employeeName {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    word-break: break-all;
  }
}

.flex {
  display: flex;
  gap: 8px;
  align-items: center;
}

.teamWrap {
  td {
    height: 48px;
    background: #f0f4f6;

    &:hover {
      cursor: pointer;
    }
  }

  .team {
    min-width: 160px;
    max-width: 160px;
    font-weight: 700;
    line-height: 18px;
  }
}

.teamArrow {
  transition: transform 0.1s ease;
  svg {
    width: 6px;
    height: 11px;
    margin-bottom: 2px;

    path {
      stroke-width: 1.5px;
      stroke: #7f8495;
    }
  }
}

.arrowOpen {
  transform: rotate(90deg);
}

.openedArrow {
  transition: transform 0.1s ease;
}

.closedArrow {
  transition: transform 0.1s ease;
  transform: rotate(180deg);
}

// table cells
.theadCell {
  text-align: center;
  height: 75px;
  font-weight: 700;

  .firstCell {
    min-width: 160px;
    max-width: 160px;
    text-align: left;
    border-right: 1px solid #f0f4f6;
  }

  .totalCell {
    min-width: 48px;
    max-width: 48px;
    border-left: 1px solid #f0f4f6;
    border-right: 1px solid #f0f4f6;
  }

  .hoursCell {
    padding: 0;
  }

  .calendarCell {
    white-space: normal;
    max-width: 40px;
    padding: 0;
  }

  .weekday {
    color: #7f8495;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
}

.employeeDetail {
  td {
    padding: 4px 8px;
  }

  &:hover {
    cursor: pointer;
  }
}

.employeeCell {
  background: #ffffff;
  min-width: 160px;
  max-width: 160px;
  text-align: left;
}

.employeeInfo {
  background: #ffffff;
}

.projectRow {
  td {
    height: 40px;
    padding: 0 8px;
    line-height: 18px;
  }
}

.projectIcon {
  width: 24px;
  height: 24px;
}

.projectTitleCell {
  padding-left: 40px;
}

// fixed cells
.tableCellsFixed {
  > th,
  > td {
    &:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
    }
    &:nth-child(2) {
      position: -webkit-sticky;
      position: sticky;
      left: 160px;
      z-index: 1;
    }
    &:nth-child(3) {
      position: -webkit-sticky;
      position: sticky;
      left: 208px;
      z-index: 1;
    }
    &:nth-child(4) {
      position: -webkit-sticky;
      position: sticky;
      left: 256px;
      z-index: 1;
    }
    &:nth-child(5) {
      position: -webkit-sticky;
      position: sticky;
      left: 304px;
      z-index: 1;
    }
    &:nth-child(6) {
      position: -webkit-sticky;
      position: sticky;
      left: 352px;
      z-index: 1;
    }
    &:nth-child(7) {
      position: -webkit-sticky;
      position: sticky;
      left: 400px;
      z-index: 1;
    }
    &:nth-child(8) {
      position: -webkit-sticky;
      position: sticky;
      left: 448px;
      z-index: 1;
      border-right: 1px solid #4f75ff;
    }
  }
}

.firstCellFixed {
  > th,
  > td {
    &:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
      border-right: 1px solid #4f75ff;
      width: 160px;
    }
  }
}

//button
.expandButton {
  position: absolute;
  z-index: 2;
  top: 27px;
  width: 24px;
  height: 24px;
  background: #4f75ff;
  border: none;
  border-radius: 50%;

  svg {
    margin-bottom: 3px;
    width: 7px;
    height: 12px;

    path {
      stroke: #ffffff;
      stroke-width: 1px;
    }
  }

  &:hover {
    background: #3f5fd4;
  }
}

.open {
  left: 484px;
}

.closed {
  left: 148px;

  svg {
    transform: rotate(180deg);
    margin-left: 2px;
  }
}

// calendar cells and legends colors
.downtime {
  background: #9b70ff;
}

.holiday {
  background: #f0f4f6;
}

.project {
  background: #f9fafb;
}

.none {
  background: #ffffff;
}

.dayOff {
  background: #7f8495;
}

.loggedMore {
  background: #ff727c;
}

.correctLog {
  background: #6bd387;
}

.loggedLess {
  background: #ffc891;
}

.shift {
  background: #51bed8;
}

.shifted {
  background: #f0f4f6;
}

.tealBorder {
  border: 1px solid #51bed8;
}
