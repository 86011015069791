.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;

  a {
    font-size: 14px;
    line-height: 20px;
  }
}

.vp {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
}

.circle {
  display: inline-block;
  width: 3px;
  height: 3px;
  background: #40475d;
  border-radius: 50%;
}

.teamTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
}

.generalInfo {
  display: flex;
  justify-content: space-between;
}

.infoTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}
.infoData {
  font-size: 14px;
  line-height: 20px;
  color: #5f6679;
}
.techLeadInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.teamInfoWrapper {
  padding: 16px;
  border: 1px solid #e6e7ed;
  border-radius: 4px;
}

.teamMembers {
  width: fit-content;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 24px;
}
