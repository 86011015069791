.toggle {
  margin-bottom: 0;
}

.toggle__switch {
  display: inline-block;
  background: transparent;
  border: 1px solid #7f8495;
  border-radius: 100px;
  width: 48px;
  height: 24px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;

  &:hover {
    border: 1px solid #4f75ff;
    cursor: pointer;
  }

  &:before,
  &:after {
    content: '';
  }

  &:before {
    display: block;
    background: #7f8495;
    border: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }

  .toggle:hover &:before {
    background: #4f75ff;
  }

  .toggle__checkbox:checked + & {
    background: #4f75ff;
    border: none;

    &:hover {
      background: #3f5fd4;
      cursor: pointer;
    }

    &:before {
      background: #ffffff;
      left: 28px;
    }
  }

  .toggle__checkbox:disabled + & {
    background: #dce3ff;
    border: none;

    &:before {
      background: #bec1cc;
    }

    &:hover {
      background: #dce3ff;
    }
  }
}

.toggle__checkbox {
  position: absolute;
  visibility: hidden;
}
