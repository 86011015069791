.wrapTable {
  position: relative;

  tr {
    &:last-child {
      td {
        border-bottom: none;

        &:first-child {
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}

.tableLoader {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#40475d, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTopBlock {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  background: #ffffff;
  padding-bottom: 16px;
}

.fixedHeader {
  th {
    position: -webkit-sticky;
    position: sticky;
    top: 115px;
    z-index: 2;

    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  .thBorderWrap {
    th {
      border-top: none;
      border-bottom: none;
      box-shadow: inset 0 1px 0 #e6e7ed, inset 0 -1px 0 #e6e7ed;
    }
  }
}

.verticalLine {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, transparent 45px, #ffa447 45px);

  th {
    background: transparent;
    padding: 0;
  }

  svg {
    margin-top: 40px;
    margin-left: -7px;
  }
}

.projectRowWrap {
  td {
    height: 40px;
    background: #f0f4f6;
    font-weight: 700;

    &:first-child {
      width: 250px;
      border-bottom: none;
      border-right: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.project {
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 18px;
}

.projectArrow {
  transition: transform 0.1s ease;
  svg {
    width: 6px;
    height: 11px;
    margin-bottom: 2px;

    path {
      stroke-width: 1.5px;
      stroke: #7f8495;
    }
  }
}

.arrowOpen {
  transform: rotate(90deg);
}

.wrapPir {
  position: relative;

  td {
    height: 40px;
    background: #f9fafb;

    &:first-child {
      border-bottom: none;
      border-right: none;
    }
  }

  .pirText {
    font-weight: 500;
    height: 40.5px;

    &:hover {
      background: #dce3ff;
    }
  }

  .summary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .widthWithRRNotCreate {
    max-width: 220px;
  }

  .widthWithRR {
    max-width: 195px;
  }

  .widthWithoutRR {
    max-width: 240px;
  }

  &:hover .resourceRequestName {
    background: #dce3ff;
  }
}

.pirDuration {
  background: #3f5fd4;
  height: 40px;
}

.rrDuration {
  background: #7291ff;
  height: 42px;
}

.red {
  color: #ff4f5b;
}

.resourceRequestRow {
  td {
    height: 42px;
    background: #fff;
  }

  .resourceRequestName {
    padding: 6px 8px;
    white-space: nowrap;
    width: 250px;
  }

  .employeeName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rrItem {
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.141px;
}

.nonBillableRR {
  background: #ffdcde;
  color: #a82f38;
}

.teamWrapper {
  color: #3f5fd4;
  background: rgba(63, 95, 212, 0.2);
}

.statusWrapper {
  position: absolute;
  z-index: 1;
  padding: 0 8px 2px;
  margin-top: 10px;
  border-radius: 24px;
  background: #fff;
  color: #4f75ff;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.tooltipWrap {
  position: absolute;
  z-index: 10;
  font-size: 12px;
  background: #fefefe;
  padding: 6px 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.plusButton {
  height: 16px;
  width: 16px;
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}

.navButtons {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.arrowButton {
  border: none;
  background: transparent;
}

.wrapDatepicker {
  input {
    background: transparent;
    pointer-events: none;

    &:focus {
      background: transparent;
    }
  }
}

.monthRange {
  position: absolute;
  top: 28px;
  left: 55px;
  font-size: 1rem;
  font-weight: 400;
}

.updateArrow {
  svg {
    width: 8px;
    height: 8px;
    transform: rotate(90deg);

    path {
      stroke: #4f75ff;
    }
  }
}

.nextStatus {
  padding: 4px 8px;
  cursor: pointer;
}

.wrapRRstatus {
  padding: 1px 8px;
  width: fit-content;
  border-radius: 24px;

  div {
    gap: 4px;
  }
}

// change RR status bg
.draft {
  position: relative;
  &:hover,
  &:focus {
    background: #f0f4f6;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #7f8495;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.fin-check {
  position: relative;
  &:hover,
  &:focus {
    background: #f0f4f6;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #40475d;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.requested,
.blocked {
  position: relative;

  &:hover,
  &:focus {
    background: #dce3ff;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #4f75ff;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.in-review {
  position: relative;

  &:hover,
  &:focus {
    background: #ffedda;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #ffa447;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.approved {
  position: relative;

  &:hover,
  &:focus {
    background: #ecf9f0;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #46c869;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.waiting-for-confirmation {
  position: relative;

  &:hover,
  &:focus {
    background: #eef8fb;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #51bed8;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.purchased {
  position: relative;

  &:hover,
  &:focus {
    background: #e6dbff;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #824cff;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.rejected {
  position: relative;

  &:hover,
  &:focus {
    background: #ffedef;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #ff4f5b;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
