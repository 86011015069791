.wrapper {
  max-width: 725px;
  text-align: center;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 60px;
}

.subheading {
  font-size: 16px;
  line-height: 24px;
  margin: 16px auto 42px;
}

.buttonLink {
  padding: 10px 32px;
  background: #4f75ff;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    text-decoration: none;
    color: #ffffff;
    background: #3f5fd4;
  }
}
