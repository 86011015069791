.tableWrap {
  table {
    overflow: hidden;
    color: #40475d;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 4px;
    overflow: hidden;

    tbody {
      tr:not(:first-child) {
        td {
          border-top: none;
        }
      }

      td {
        border-left: none;
        width: 25%;
        height: 40px;
        padding: 4px 8px;
      }
    }

    td {
      border: 1px solid #e6e7ed;

      &:first-child {
        border-left: 1px solid #e6e7ed;
      }

      &:last-child {
        border-right: 1px solid #e6e7ed;
      }
    }

    tbody tr:first-child td:last-child + td {
      border-bottom-left-radius: 4px;
    }

    tbody tr:last-child {
      td {
        border-bottom: 1px solid #e6e7ed;
      }
    }

    tbody tr:first-child td:last-child {
      border-top-right-radius: 4px;
    }

    tbody tr:first-child td:first-child {
      border-top-left-radius: 4px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
}

.lastDoubleCell {
  td:last-child {
    border-bottom-right-radius: 4px;
  }
}

.noObligations {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px auto 120px;
}

.greyCell {
  background: #f0f4f6;
}

.removeTopBorder {
  td {
    border-top: none;
  }
}

.obligationTableItem {
  input {
    border: 1px solid transparent;
  }
}

.heading {
  color: #40475d;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.cellTextMargin {
  color: #40475d;
  padding-left: 10px;
}

.emptyCell {
  display: block;
  min-width: 100px;
  width: 100%;
  min-height: 30px;
  height: 100%;
}

.typesWrapper {
  padding-left: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.expenseType {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #3f5fd4;
  padding: 4px;
  border-radius: 4px;
  background: rgba(63, 95, 212, 0.2);
}

.cellTextHover {
  &:hover {
    width: 100%;
    height: 100%;
    align-self: center;
    border-radius: 4px;
    background: #f0f4f6;
    display: flex;
    align-items: center;
  }
}

// edit mode
.inputWrap {
  input {
    padding: 3px 10px;
    height: 32px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #40475d;
    border-radius: 4px;
    border: 1px solid #e6e7ed;
  }
}

.error {
  background: #ffdcde;
}
