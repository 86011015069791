.engineeringlead {
  color: #ffa447;
}

.teamlead {
  color: #ff4f5b;
}

.techlead {
  color: #46c869;
}
