.mainContainer {
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    display: block;
    text-align: center;
  }
}

.whiteBox {
  position: relative;
  background: #e0e0e0;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%;
    min-height: 28%;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 35%;
  }
}

.towerLogo {
  position: absolute;
  top: 40px;
  left: 85px;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #40475d;
  text-transform: uppercase;

  @media (max-width: 991px) {
    left: 48px;
  }

  @media (max-width: 767px) {
    left: 24px;
    font-size: 18px;
  }
}

.signInBlock {
  background: #ffffff;
  padding: 48px 40px 32px;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  @media (max-width: 991px) {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .signInText {
    margin-bottom: 32px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .welcomeText {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #212529;
    margin-bottom: 8px;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
}

.grayBox {
  background: #f3f5f9;
  width: 60%;
  overflow: hidden;
  display: block;

  @media (max-width: 991px) {
    width: 100%;
    min-height: 72%;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 65%;
  }

  .grayBlock {
    margin-left: 64px;
    position: relative;

    @media (max-width: 991px) {
      margin-left: 48px;
    }

    @media (max-width: 767px) {
      margin-left: 24px;
    }
  }

  .grayText {
    margin-top: 60px;
    margin-bottom: 40px;

    @media (max-width: 991px) {
      margin-top: 56px;
      margin-bottom: 48px;
      padding-right: 48px;
    }

    @media (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 24px;
      padding-right: 24px;
    }
  }

  .mainText {
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    color: #212529;
    margin-bottom: 16px;

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 47px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 12px;
    }
  }
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #40475d;
  margin: 0;

  @media (max-width: 991px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.mainImg {
  height: 100vh;
  background: url(../../assets/utils/images/tower_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.loaderWrapper {
  padding-bottom: 8px;
}
