.hoursMainCell {
  text-align: center;
}

.headerCell {
  th {
    padding: 10px 8px;
  }
}

.detailsCell {
  th {
    height: 60px;
    padding: 10px 8px;
  }

  th,
  td {
    white-space: normal;
  }

  .hoursCell {
    width: 88px;
  }

  .payoutCell {
    min-width: 67px;
    max-width: 67px;
  }

  .compensationCell {
    min-width: 82px;
    max-width: 82px;
    word-break: break-all;
  }
}

.employeeDetailCell {
  white-space: nowrap;
}

.employeeCell {
  max-width: 150px !important;
  min-width: 150px !important;
}

.totalPayoutCell {
  min-width: 75px;
  max-width: 75px;
}

.requestsCell {
  min-width: 100px;
  max-width: 100px;
}

.amount {
  padding-left: 4px;

  &:hover {
    width: 100%;
    height: 32px;
    align-self: center;
    border-radius: 4px;
    background: rgba(71, 115, 255, 0.2);
    display: flex;
    align-items: center;
  }
}

.buttonWrap {
  display: flex;
  gap: 12px;

  button {
    font-weight: 500;
    line-height: 16px;
    background: transparent;
    padding: 8px 24px;
    border-radius: 4px;

    &:disabled {
      color: #bec1cc;
      border: none;
    }
  }
}

.buttonRecalculate {
  color: #4f75ff;
  border: 1px solid #4f75ff;

  &:hover {
    background-color: rgba(71, 115, 255, 0.2);
  }

  &:disabled {
    background-color: #dce3ff;
  }
}

.buttonResend {
  color: #46c869;
  border: 1px solid #46c869;

  &:hover {
    background-color: #daf4e1;
  }

  &:disabled {
    background-color: #daf4e1;
  }
}

.inputWrap {
  input {
    padding: 3px;
    height: 32px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #40475d;
    border-radius: 4px;
    border: 1px solid #e6e7ed;

    &:focus {
      box-shadow: 0 0 0 0.05rem rgba(0, 123, 255, 0.25);
    }
    &::placeholder {
      font-size: 12px;
    }
  }
}
