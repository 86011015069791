.orderWrapper {
  padding: 20px 16px 16px;
  border: 1px solid #46c869;
  border-radius: 8px;
  margin-bottom: 32px;
}
.topInfo {
  margin-bottom: 12px;
}

.orderInfo {
  display: flex;
  gap: 14px;

  .month {
    font-size: 16px;
    line-height: 24px;
    color: #1a1c25;
  }
}

.lineBtn {
  background: transparent;
  border: 1px solid #4f75ff;
  border-radius: 4px;
  padding: 8px;
  display: flex;

  &:hover {
    background: #dce3ff;
  }
}

.tableContainer {
  td {
    width: 25%;
    height: 40px;

    &:first-child,
    &:nth-child(3) {
      background: #f9fafb;
    }
  }
}

.amount {
  font-weight: 700;
}
