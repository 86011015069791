.block {
  display: flex;
  gap: 9px;
}

.blockItem {
  max-width: 12.5%;
  width: 100%;
  padding: 23px 7.5px 8px;
  border-radius: 4px;
  color: #ffffff;

  p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  span {
    font-weight: 400;
    line-height: 20px;
  }
}
