.wrapInfoProfile {
  padding-bottom: 30px;
  color: #40475d;
  &.wrapInfoProfileStatic {
    input {
      border: 1px solid transparent;
      &:disabled {
        background-color: transparent;
      }
    }
  }
  input {
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid #e6e7ed;
    border-radius: 4px;
    min-height: 40px;
    font-size: 14px;
    width: 100%;
    max-width: 310px;
    color: #40475d;
    @media (max-width: 1500px) {
      max-width: 230px;
    }
  }
  input[type='date'] {
    max-width: 170px;
  }
  ::placeholder {
    color: #9fa2ae;
  }
  .block {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 0px 4px 4px 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .blockPadding {
    padding: 20px;
  }

  .value {
    margin-left: 10px;
    font-size: 14px;
    &.labelCheckbox {
      margin-top: 9px;
    }
    span {
      display: inline-block;
      margin-right: 10px;
      min-width: 45px;
    }
    a {
      color: #4f75ff;
      border: 0;
      background-color: transparent;
    }
  }

  .vsdButton {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    border: 0;
    border-radius: 4px;
    background-color: #545cd8;
    padding: 7.5px 12px;

    &:hover {
      background-color: darken(#545cd8, 10%);
    }

    &:disabled {
      background-color: rgba(#545cd8, 0.5);
    }
  }

  .titleBlock {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    border-bottom: 1px solid #e6e7ed;
  }
  .vsdItems {
    display: flex;
    align-items: center;
  }
  .item {
    display: flex;
    @media (min-width: 1200px) {
      max-width: 48%;
      flex: 0 0 48%;
    }
    a {
      margin-bottom: 5px;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .downItem {
    display: flex;
    height: 40px;
    @media (min-width: 1200px) {
      max-width: 48%;
      flex: 0 0 48%;
    }
  }
}

.textMargin {
  padding-top: 22px;
}

.blockWrap {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 20px;

  .label {
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
    min-width: 152px;
    line-height: 21px;
  }

  .notEditLabel {
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
    min-width: 152px;
    line-height: 40px;
  }
}

.flex {
  display: flex;
}

.removeMargin {
  margin-top: -10px;
  padding-bottom: 8px;
}

.onlyContact {
  margin-top: -10px;
  padding-bottom: 0;
}

.blockEdit {
  display: grid;
  gap: 10px;
}

.privateBlockWrap {
  margin-top: 10px;
  width: 100%;

  &:first-child {
    margin-right: 180px;
  }

  .item {
    max-width: 500px;
    width: 100%;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
    min-width: 152px;
    line-height: 43px;
  }
}

.rowWrap {
  display: flex;

  .availabilityInput {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }

  .item {
    max-width: 46%;
    width: 100%;
    height: 40px;
    & > div {
      width: 100%;
      max-width: 250px;
    }
  }
  .itemStatic {
    align-items: center;
    p {
      margin-top: 0;
    }
  }
  p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
    min-width: 152px;
    line-height: 21px;
  }
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
  span {
    margin-left: 10px;
  }
}

.headerButtons {
  display: flex;
}

.emergencyContact {
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  &_label {
    margin-top: 9px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
    min-width: 152px;
    line-height: 21px;
  }

  &_value {
    display: flex;
    align-items: center;
    margin-top: 9px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}
.editButton {
  top: 45px;
  right: 36px;
  position: absolute;
}

.amount {
  font-weight: 700;
  margin-left: 4px;
}

// styles for tabs
.hideContent {
  display: none;
}

.tabs {
  display: flex;
}

.tab {
  padding: 15px 20px 14px;
  text-align: left;
  max-width: 188px;
  width: 100%;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  color: rgba(64, 71, 93, 0.5);
  cursor: pointer;
  border: none;
  border-bottom: 2px solid #e9ecef;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.tab:not(:last-child) {
  border-right: 2px solid #e9ecef;
}

.activeTab {
  padding: 15px 0 14px 20px;
  text-align: left;
  max-width: 188px;
  width: 100%;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #40475d;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.activeTab:not(:last-child) {
  border-right: 2px solid #e9ecef;
}

.menteesTable {
  th:not(:first-child) {
    width: 32%;
  }
}
