.inputWrap {
  padding: 0 50px;
  .input {
    text-align: center;
    input {
      margin: 0 auto;
      padding: 10px;
      background: rgba(255, 255, 255, 0.0001);
      border: 1px solid #e6e7ed;
      border-radius: 4px;
      width: 100%;
      max-width: 330px;
    }
  }
}
.btnWrap {
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}
