.photoBlock {
  margin-bottom: 30px;
  display: flex;
}
.photoWrap {
  margin-right: 20px;
  width: 176px;
  height: 176px;
  min-width: 176px;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
}

.textBlock {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.textWrap {
  display: flex;
  flex-wrap: wrap;
  .inputWrap {
    margin-bottom: 20px;
    margin-right: 20px;
    flex: 0 0 26%;
    max-width: 26%;
    color: #ff4f5b;
    min-width: 185px;

    p {
      margin-bottom: 5px;
      color: #9fa2ae;
    }
    &.longInputWrap {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}
.name {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.jobTitle {
  font-size: 16px;
  font-weight: 600;
  color: #40475d;
  margin-right: 8px;
}

.gradeWrap {
  display: flex;
  align-items: center;

  .trainee {
    padding: 6px 11.5px;
    font-size: 12px;
    font-weight: 500;
    color: #40475d;
    background-color: rgba(#40475d, 0.2);
    border-radius: 4px;
  }

  .middle {
    display: none;
  }

  .junior {
    padding: 6px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #51bed8;
    background-color: rgba(#51bed8, 0.2);
    border-radius: 4px;
  }

  .senior {
    padding: 6px 13.5px;
    font-size: 12px;
    font-weight: 500;
    color: #4f75ff;
    background-color: rgba(#4773ff, 0.2);
    border-radius: 4px;
  }
}

.teamWrap {
  display: flex;
  .wrap {
    margin-right: 80px;
  }
  .label {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #9fa2ae;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
  }
}
