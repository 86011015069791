.buttonsWrap {
  display: flex;
  gap: 12px;

  button {
    padding: 8px 24px 8px 25.5px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background-color: transparent;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;

    &:disabled {
      svg {
        path {
          fill: #bec1cc;
          stroke: #bec1cc;
        }
      }
    }
  }

  .approve {
    border: 1px solid #46c869;
    color: #46c869;

    &:hover {
      background: #daf4e1;
    }

    &:disabled {
      color: #bec1cc;
      background: #daf4e1;
      border: none;
    }
  }

  .reject {
    border: 1px solid #ff4f5b;
    color: #ff4f5b;

    &:hover {
      background: #ffdcde;
    }

    &:disabled {
      color: #bec1cc;
      background: #ffdcde;
      border: none;
    }
  }

  .common {
    border: 1px solid #4f75ff;
    color: #4f75ff;

    &:hover {
      background: #dce3ff;
    }
  }

  .deleteBin {
    padding: 8px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
