.calendarWrapper {
  position: relative;
}

.calendarIcon {
  position: absolute;
  z-index: 100;
  top: 8px;
  right: 10px;

  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: #4f75ff;
      }
    }
  }
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker {
  border: none;
  border-radius: 4px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  cursor: default;
}

.react-datepicker__month {
  padding: 12px 16px 60px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 3px;

  &:hover {
    cursor: default;
  }
}

.react-datepicker__month-wrapper {
  display: flex;
  gap: 3px;
}

.react-datepicker__navigation-icon--previous:before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-text {
  width: 63px;
  font-weight: 400;
  padding: 10px 0;

  &--keyboard-selected {
    background: #4f75ff;
    border-radius: 4px;
    font-weight: 600;
    color: #ffffff;

    &.react-datepicker__month-text--today {
      color: #ffffff;
    }
  }

  &--today {
    color: #4f75ff;
  }

  &:hover {
    cursor: pointer;
    background-color: #4f75ff;
    border-radius: 4px;
    font-weight: 600;
    color: #ffffff;
  }
}

.react-datepicker__day-names {
  background: transparent;
}

.react-datepicker__day {
  &--selected {
    background: #4f75ff;

    &:hover {
      background: #4f75ff;
    }

    &.react-datepicker__day--today {
      color: #ffffff;
    }
  }

  &--keyboard-selected {
    background: transparent;
    border: 1px solid #4f75ff;
    color: #4f75ff;

    &:hover {
      background: #4f75ff;
      color: #ffffff;
    }
  }

  &--today {
    color: #4f75ff;

    &:hover {
      background: #4f75ff;
      color: #ffffff;
    }
  }
}

.react-datepicker__header {
  padding-top: 16px;
  border-bottom: none;
  font-weight: 700;
}

.greyLine {
  position: absolute;
  margin: 0 16px;
  width: 100%;
  bottom: 56px;
  height: 1px;
  background: #e6e7ed;
  z-index: 1;
}

.year {
  max-width: 195px;
}

.month {
  max-width: 260px;
}

.quarter {
  max-width: 150px;
}

.selectThisMonth {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background: #4f75ff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  outline: none;

  &:hover {
    background-color: #3f5fd4;
  }
}

// quarter datepicker
.react-datepicker__quarter-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  max-width: 150px;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.react-datepicker__quarter {
  &-text {
    width: 63px;
    font-weight: 400;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
      background-color: #4f75ff;
      border-radius: 4px;
      font-weight: 600;
      color: #ffffff;
    }

    &--keyboard-selected {
      background: #4f75ff;
      border-radius: 4px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}

// next and previous arrows
.react-datepicker__navigation {
  display: flex;
  justify-content: center;
  border: none;
  height: 25px;
  width: 25px;
}

.react-datepicker__navigation-icon {
  position: relative;
  width: 0;
}

.react-datepicker__navigation-icon:before {
  border-color: #7f8495;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  height: 8px;
  position: absolute;
  top: 8px;
  width: 8px;
}

.react-datepicker__navigation-icon--next {
  &::before {
    transform: rotate(45deg);
    left: -7px;
  }
}

// prevent cutting off the popup
.modalWrapper {
  .react-datepicker {
    max-height: 350px;
  }
}

.smallScreenHeightWrapper {
  .react-datepicker {
    max-height: 260px;
    overflow-y: auto;
  }
}
