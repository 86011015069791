.button {
  border: none;
  background: none;
  vertical-align: middle;

  .arrowBox {
    display: flex;
    justify-content: space-evenly;
    height: 16px;
    flex-direction: column;
  }
  .arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .upArrow {
    border-bottom: 5px solid #7f8495;
  }
  .downArrow {
    border-top: 5px solid #7f8495;
  }
  .activeUpArrow {
    border-bottom-color: #40475d;
  }
  .activeDownArrow {
    border-top-color: #40475d;
  }
}

.cursorPointer {
  &:hover {
    cursor: pointer;
  }
}
