.css-6y1x9t-control {
  border-color: #e6e7ed !important;
  box-shadow: none !important;

  &:hover {
    border-color: #e6e7ed !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }
}

.css-1qprcsu-option {
  background-color: transparent !important;
  color: #40475d !important;
  font-weight: 400 !important;
}

.css-1pcexqc-container {
  border: 1px solid #e6e7ed;
  border-radius: 4px;
  box-shadow: 0 0.46875rem 2.1875rem #00114f08, 0 0.9375rem 1.40625rem #00114f08, 0 0.25rem 0.53125rem #00114f0d,
    0 0.125rem 0.1875rem #00114f08;
}
