.role-dropdown-wrap {
  margin-left: -4px;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  .rw-widget-container {
    background: transparent;
    border: 1px solid #30b1ff;

    .rw-dropdown-list-input {
      padding-left: 4px;
      color: rgba(255, 255, 255, 0.8);
    }

    .rw-i-caret-down:before {
      border-color: rgba(255, 255, 255, 0.8);
      height: 6px;
      width: 6px;
    }
  }

  .rw-state-focus > .rw-widget-container {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    box-shadow: none;
  }

  .rw-popup {
    .rw-list-option {
      padding: 4px;
    }
  }
}
