.tableWrapper {
  tr {
    th:first-child {
      min-width: 240px;
      max-width: 240px;
    }
    th:nth-child(2) {
      min-width: 98px;
      max-width: 98px;
    }
  }
}

.tableHeader {
  th {
    height: 75px;
  }

  th:not(:first-child) {
    border-left: none;
    width: 11%;
  }

  th:not(:last-child),
  th:not(:nth-child(4)) {
    border-right: none;
  }

  th:nth-child(4) {
    border-right: 1px solid #4f75ff;
  }
}

.cellAccentLine {
  td:nth-child(4) {
    border-right: 1px solid #4f75ff;
  }
  td:nth-child(5) {
    border-left: none;
  }
}

.mergedCellsAccentLine {
  td:nth-child(3) {
    border-right: 1px solid #4f75ff;
  }
  td:nth-child(4) {
    border-left: none;
  }
}

.employee {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamLead {
  td {
    background: #f9fafb;
  }
}

.teamTitleRow {
  background: #f0f4f6;

  td {
    height: 40px;
  }
}

.arrow {
  i {
    color: #7f8495;
    margin-left: -8px;
    transition: all 0.1s ease;
    transform: translateY(25%);

    &:before {
      font-size: 24px;
      line-height: 16px;
    }
  }
}

.arrowOpened {
  i {
    transition: all 0.1s ease;
    transform: rotateX(180deg);
  }
}

.department {
  td {
    background: #e6dbff;
  }
}

.practice {
  td {
    background: #dce3ff;
  }
}
