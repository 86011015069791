.obligationDropdownWrap {
  .rw-widget-container {
    height: 31px;
    width: 100%;
    color: #40475d;
    border: 1px solid #e6e7ed;

    .rw-dropdown-list-input {
      padding: 0;
      font-size: 14px;
      line-height: 20px;
    }

    .rw-input-reset {
      color: #40475d;
      opacity: 1;
      padding: 0;
      height: 20px;
    }
  }

  .rw-state-disabled {
    opacity: 1;
  }

  .rw-multiselect-tag {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #3f5fd4;
    padding: 4px;
    border: none;
    border-radius: 4px;
    background: rgba(63, 95, 212, 0.2);
    margin: 0;
  }
}

.dropdownPadding {
  .rw-widget-container {
    padding: 3px 10px;
  }
}

.multiselectPadding {
  .rw-widget-container {
    min-height: 31px;
    height: fit-content;
    padding: 3px 8px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .rw-select {
      max-height: 16px;
      max-width: 16px;
    }
  }
}
