.formWrap {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;

  .label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.14px;
    color: #9fa2ae;
  }

  .generalInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 450px;
    width: 100%;
    .generalInfoItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
    .inputWrap {
      max-width: 311px;
      width: 100%;
      position: relative;
    }
    .formInput {
      color: #40475d;
      padding-left: 12px;
      width: 100%;
      min-height: 40px;
      border: 1px solid #e6e7ed;
      border-radius: 4px;
    }
    .checkboxWrap {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .datePickerWrap {
      position: relative;
      max-width: 170px;
    }
    .datePicker {
      max-width: 170px;
      background: #fff;
    }
    .checkboxLabel {
      font-size: 14px;
      line-height: 17px;
    }
    .checkbox {
      width: 16px;
      height: 16px;
    }
  }
  .membersWrap {
    display: flex;
    justify-content: space-between;
    max-width: 490px;
    width: 100%;
    padding-left: 40px;
    .selectLable {
      margin-right: 70px;
    }
    .selectWrap {
      max-width: 311px;
      width: 100%;
      button {
        width: 311px !important;
        text-transform: none;
        box-shadow: none;
        border: 1px solid #e6e7ed;
        border-radius: 4px;

        svg {
          display: none;
        }

        span {
          &:last-child::before {
            content: '';
            border: 1px solid #40475d;
            border-width: 0 1px 1px 0;
            display: inline-block;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            height: 9px;
            width: 9px;
            margin-bottom: 5px;
            margin-right: 2px;
          }
        }
      }
      input {
        &:focus {
          box-shadow: none;
        }
      }
    }
    .membersContainer {
      margin-top: 20px;
      .membersItem {
        font-size: 14px;
        margin-bottom: 9px;
      }
      .removeMember {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .hasError {
    border-color: #ff4f5b !important;
  }
  .errorMsg {
    position: absolute;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    font-weight: 500;
    color: #ff4f5b;
  }
  .selectInput {
    color: #40475d;
    width: 100%;
    min-height: 40px;
    border: 1px solid #e6e7ed;
    border-radius: 4px;
  }
  .isRemoveTL {
    position: absolute;
    top: 13px;
    right: 34px;
    cursor: pointer;
  }
}
