.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#40475d, 0.5);
  max-width: 100%;
  z-index: 100;
  .block {
    padding: 26px 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0px rgba(64, 71, 93, 0.1);
    min-width: 500px;
    max-height: 474px;
    overflow-y: auto;
  }
  .close {
    top: 25px;
    right: 25px;
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: transparent;
    border: 0;
    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin-left: -7px;
      margin-top: -7px;
      width: 2px;
      height: 14px;
      background-color: #9fa2ae;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .name {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .itemWrap {
    margin-bottom: 30px;
  }
  .item {
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid #e6e7ed;
  }
  .value {
    a {
      display: inline-block;
      color: #4f75ff;
      min-width: 65px;
    }
    .paymentType {
      margin-left: 20px;
      display: inline-block;
    }
  }
}
