[data-amplify-footer] {
  display: none;
}

.data-amplify-form {
  padding: 0;
  border-radius: 4px;
}

[data-amplify-theme] {
  box-shadow: none;
}

:root {
  --amplify-shadows-medium: none;
  --amplify-border-widths-small: 0;
  --amplify-components-button-link-border-color: #007bff;
  --amplify-components-button-link-background-color: #007bff;
  --amplify-components-authenticator-container-width-max: fit-content;
  --amplify-components-button-link-focus-outline: none;
}

[data-amplify-authenticator] {
  [data-amplify-form] {
    padding: 0;
    box-shadow: none;
  }
}

.amplify-divider {
  display: none;
}

.amplify-textfield {
  display: none;
}

.amplify-button {
  border-radius: 4px;
  border-width: 0;
  border: none;
  background-color: #4f75ff;
  padding-block-start: 14px;
  padding-block-end: 14px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;

  &:hover {
    background-color: #3f5fd4;
  }

  svg {
    display: none;
  }
}

.amplify-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.amplify-button--primary {
  display: none;
}
