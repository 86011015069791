.wrapContacts {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    margin-bottom: 0;
  }
  .buttonWrap {
    span {
      margin-right: 0;
      min-width: 45px;
    }
  }
  .wrapContactsButton {
    min-width: 53px;
  }
  .removeContacts,
  .addContacts {
    background-color: transparent;
    border: 0;
    width: 24px;
    height: 24px;
  }
  .label {
    margin-top: 6px;
  }
  .hideDeleteButton {
    display: none;
  }
  .removeContacts {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' stroke='%23979797' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M8.25 12H15.75' stroke='%23979797' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  .addContacts {
    margin-left: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' stroke='%234F75FF' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M8.25 12H15.75' stroke='%234F75FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8.25V15.75' stroke='%234F75FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &.wrapContactsButtonAdd {
    button {
      margin-left: -5px;
    }
  }
}
