.searchKeyWrap {
  position: relative;
  display: flex;
  align-items: center;

  input {
    height: 40px;
    font-size: 15px;
  }

  .buttonSearch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 38px;
    height: 38px;
    border: 0;
    background-color: transparent;
  }
}
