.changeStatusRRtooltip {
  .tooltip {
    width: 209px;

    .tooltip-inner {
      padding: 12px 0;
    }
  }
}

.orderActionsTooLtip {
  .tooltip {
    max-width: 150px;
    padding-top: 12px;

    .tooltip-inner {
      border: 1px solid #e6e7ed;
      border-radius: 8px;
      padding: 12px 8px;

      div {
        font-size: 16px;
        line-height: 24px;
        padding: 4px 12px;

        &:hover {
          background: #e6e7ed;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
  width: 280px;

  .tooltip-inner {
    max-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: nowrap;
    padding: 16px;
    text-align: left;
    font-size: 12px;
    background: #fefefe;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #40475d;
  }

  .arrow {
    display: none;
  }
}
